import React, { useState } from 'react';

function Posts({ posts, tagStr, updateTagFilter }) {
    const [hoverTag, setHoverTag] = useState('');

    return (
        <div className="wrapper">
            <div className="posts">
                {posts.map(p => {
                    return (
                        <div key={p.title} className="post">
                            <div className="post-header">
                                <h2>
                                    <a
                                        href={`https://blog.jsdisco.dev${p.slug}`}
                                    >
                                        {p.title.replace(
                                            '#100DaysOfMERN - ',
                                            ''
                                        )}
                                    </a>
                                </h2>
                            </div>
                            <div className="post-body">
                                <ul>
                                    {p.tags.map((t, i) => {
                                        const isHover = t === hoverTag;
                                        const isActive =
                                            tagStr.length > 2 &&
                                            t.includes(tagStr);
                                        const className = isHover
                                            ? 'tag hover'
                                            : isActive
                                            ? 'tag hover'
                                            : 'tag';
                                        return (
                                            <li
                                                key={`${t}-${i}`}
                                                className={className}
                                                onMouseEnter={() =>
                                                    setHoverTag(t)
                                                }
                                                onMouseLeave={() =>
                                                    setHoverTag('')
                                                }
                                                onClick={() =>
                                                    updateTagFilter(t)
                                                }
                                            >
                                                {t}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="post-footer">
                                <a
                                    href={`https://blog.jsdisco.dev${p.slug}`}
                                    target="_blank"
                                >
                                    visit blog
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Posts;
