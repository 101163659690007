import React from 'react';

function Search({ tagStr, updateTagStr, updateTagFilter, handleKeyUp }){

    return (
        <div className="search">
            <div className="wrapper">
                <div><h2>Search Tags</h2><button type="button" onClick={()=>updateTagFilter('')}>show all</button></div>
                <div>
                    <div>
                        <input spellCheck="false" value={tagStr} onChange={updateTagStr} onKeyUp={handleKeyUp}/>
                        <button type="button" onClick={updateTagFilter}>filter</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search;
