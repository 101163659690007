import React, { useState, useEffect } from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Search from './components/Search.js';
import Spinner from './components/Spinner.js';
import Posts from './components/Posts.js';
import axios from 'axios';

function App() {
    const [posts, setPosts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tagStr, setTagStr] = useState('');
    const [tagFilter, setTagFilter] = useState('');

    useEffect(() => {
        const uri = '/api/posts';
        //const uri = 'https://localhost:5000/api/posts';
        axios
            .get(uri)
            .then(res => setPosts(res.data))
            .then(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const uri = '/api/posts';
        //const uri = 'https://localhost:5000/api/posts';
        const query = `?tag=${tagFilter}`;
        axios.get(uri + query).then(res => setPosts(res.data));
    }, [tagFilter]);

    function updateTagStr(e) {
        setTagStr(e.target.value);
    }

    function updateTagFilter(str) {
        if (typeof str === 'string') {
            setTagFilter(str);
            setTagStr(str);
        } else {
            setTagFilter(tagStr);
        }
    }

    function handleKeyUp(e) {
        if (+e.keyCode === 13) {
            setTagFilter(tagStr);
        }
    }

    return (
        <div className="app">
            <Header />
            <Search tagStr={tagStr} updateTagStr={updateTagStr} updateTagFilter={updateTagFilter} handleKeyUp={handleKeyUp} />
            {isLoading ? <Spinner /> : <Posts posts={posts} tagStr={tagStr} updateTagFilter={updateTagFilter} />}
            <Footer />
        </div>
    );
}

export default App;
